@import "src/app/styles/vars.scss";

.modal {
  padding: 6px 20px 20px 20px;
  .info {
    display: flex;
    justify-content: space-between;
    gap: 22px;
    margin-bottom: 30px;
    svg {
      color: $error;
      width: 52px;
      height: 36px;
    }
    span {
      font-weight: 400;
      font-size: 18px;
      line-height: 140.62%;
      display: flex;
      color: $cold-dark-gray;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    button {
      width: 100%;
      &:first-child {
        max-width: 195px;
        width: 100%;
      }
    }
  }
}
