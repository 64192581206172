@import "@app/styles/vars.scss";
.spin {
  :global(.ant-spin-container) {
    display: flex;
  }
}
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  color: $white;
  background-color: $blue;
  font-weight: 400;
  font-size: 16px;
  line-height: 13px;
  text-transform: uppercase;
}
.user {
  margin-right: 20px;

  &__content {
    display: flex;
    align-items: center;
    color: $white;
    :global(.anticon) {
      margin-left: 5px;
      opacity: 0.6;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    font-size: 14px;
    line-height: 22px;
    span {
      opacity: 0.5;
    }
  }
}