@import "@app/styles/vars.scss";
.card__list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 50px;
  gap: 15px;
  .titleLarge {
    font-size: 16px;
    font-weight: 500;
  }
  .card {
    display: flex;
    align-items: center;
    background: #ffffff;
    color: #495057;
    box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
    border-radius: 3px;
    padding: 15px;
    cursor: pointer;
    transition: 0.3s ease all;
    &:hover {
      box-shadow: 0 3px 1px -1px $blue, 0 2px 2px $blue, 0 1px 3px #0000001f;
    }
    &:active {
      background: $blue;
      color: #fff;
      .icon {
        color: #fff;
      }
    }
  }
  .icon {
    width: 40px;
    height: 40px;
    color: #1D7DE8;
    transition: 0.3s ease all;
  }
  .text {
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 14px;
    span {
      font-weight: 500;

    }
  }
}
