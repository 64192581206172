@import "../../app/styles/vars";

.sidebar {
  position: relative;
  top: 0;
  height: 100%;
  width: 72px;
  padding: 25px 12px 44px 12px;
  box-shadow: 0 4px 8px rgba(59, 66, 86, 0.15);
  transition: width 0.3s ease-in-out;
  background-color: $white;
  &__list {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &.opened {
    width: 286px;
  }

  @media (max-width: 1024px) {
    position: absolute;
  }
}

.sidebar__link {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  width: 100%;
  height: 48px;
  padding-left: 12px;
  padding-right: 12px;
  color: $blue;
  overflow: hidden;

  &.active {
    background-color: $blue;
    color: $white;
  }
}

.sidebar__link-icon {
  display: flex;
  margin-right: 24px;
}

.sidebar__link-text {
  white-space: nowrap;
}

.sidebar__toggle-button {
  position: absolute;
  bottom: 44px;
  right: 3px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 66px;
  border: none;
  background: none;
  cursor: pointer;
}

.sidebar__toggle-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid $blue;
  border-radius: 50%;
  color: $blue;
  margin-bottom: 4px;
  transition: transform 0.3s ease-in-out;
  padding: 0 0 0 1px;

  &.opened {
    padding: 0 1px 0 0;
    transform: rotate(180deg);
  }
}

.sidebar__toggle-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: $platina;
}

@media(max-width: 1024px) {
  .sidebar__toggle-button {
    display: none;
  }
}
