@import "../../../app/styles/vars";

.clipboard {
  display: flex;
  svg {
    margin-left: 5px;
    color: $cold-gray;
    cursor: pointer;
    &:hover {
      color: $blue;
    }
  }
}
