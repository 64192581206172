@import "../../../app/styles/vars";

.secondary-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 86px;
  background-color: $white;
  padding-left: 70px;
  padding-right: 25px;
}

.secondary-header__title {
  font-weight: 500;
  font-size: 42px;
  line-height: 59px;
  color: $cold-dark-gray;
}

.secondary-header__cv-belong {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: $cold-gray;
}
