@import "../../../app/styles/vars";

.hamburger-btn {
  display: block;
  position: relative;
  border: none;
  background: none;
  width: 18px;
  height: 18px;
  overflow: hidden;
  cursor: pointer;

  &.active {
    .hamburger-btn__stick-1 {
      top: 8px;
      transform: rotate(45deg);
      width: 20px;
    }

    .hamburger-btn__stick-2 {
      width: 0;
      opacity: 0;
    }

    .hamburger-btn__stick-3 {
      top: 8px;
      transform: rotate(-45deg);
      width: 20px;
    }
  }
}

.hamburger-btn__stick-1,
.hamburger-btn__stick-2,
.hamburger-btn__stick-3 {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: $white;
  transition: 0.3s ease-in-out;
}

.hamburger-btn__stick-1 {
  top: 3px;
}

.hamburger-btn__stick-2 {
  top: 8px;
}

.hamburger-btn__stick-3 {
  top: 13px;
}
