@import "../../../../app/styles/vars";

.select_locale {
    &:global(.ant-select) {
      :global(.ant-select-selector) {
        border: none;
        background-color: inherit;
        color: $white;
        font-weight: 500;
        font-size: 16px;
        line-height: 140.62%;
      }
      :global(.ant-select-selection-item) {
        padding-right: 25px ;
        color: $white;
      }
      :global(.ant-select-arrow) {
        color: $white;
      }

    }
}
