@mixin font($fname, $fstyle, $fweight, $furl) {
  @font-face {
    font-family: $fname;
    font-style: $fstyle;
    font-weight: $fweight;
    src: url($furl) format('woff2');
  }
}

@include font('roboto', normal, 400, '../../app/assets/fonts/roboto-regular-webfont.woff2');
@include font('roboto', italic, 400, '../../app/assets/fonts/roboto-italic-webfont.woff2');
@include font('roboto', normal, 700, '../../app/assets/fonts/roboto-bold-webfont.woff2');
@include font('roboto', italic, 700, '../../app/assets/fonts/roboto-bolditalic-webfont.woff2');
@include font('roboto', normal, 500, '../../app/assets/fonts/roboto-medium-webfont.woff2');
