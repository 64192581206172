@import "@app/styles/vars.scss";
.about {

}
.form {
  display: flex;
  padding: 24px 24px 30px;
  background-color: $white;
  border-radius: 4px;
  gap: 40px;
  @media (max-width: 1165px) {
    flex-direction: column;
  }
}

.left {
  min-width: 405px;
  width: 100%;
  @media (max-width: 1165px) {
    min-width: unset;
  }
}

.right {
  width: 90%;
  @media (max-width: 1165px) {
    min-width: unset;
    width: 100%;
  }
  > *:not(:first-child) {
    margin-bottom: 20px;
  }
}

.introduction {
  margin-bottom: 16px;
}

.group {
  row-gap: 20px;
}

.margin_top {
  margin-top: 20px;
}