@import "vars";
@import "fields/index";
@import "grid";
@import "buttons";
@import "fonts";

$userfont: roboto, sans-serif; // User Font Family
$systemfont: roboto, sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', ; // System Font Family
 *{
  font-family: roboto, sans-serif !important;
}

body {
  font-family: $userfont,
  sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
.wrapper {
  width: 100%;
  margin: 0 auto;
  &-noM {
    margin: 0;
  }

}
h1, h2, h3 {
  color: $cold-dark-gray;
}
.spin-suspense {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mt15 {
  margin-top: 15px;
}

.hint-popup {
  max-width: 355px;
  padding-bottom: 0;
  top: 420px !important;
  .ant-popover-inner-content {
    display: flex;
  }
  .ant-popover-buttons {
    button {
      border: none;
      width: 24px;
      height: 24px;
      padding: 0;
      margin-top: 3px;
      color: $cold-gray;
      transition: 0.3s ease all;
      &:hover {
        color: $white;
      }
    }
  }
}
.ant-modal-title {
  margin-left: 24px !important;
}
.ant-notification {
  z-index: 2010;
}
.ant-modal-mask {
  z-index: 1060;
}
.ant-modal-wrap {
  z-index: 1061;
}
.select_language {
  z-index: 2070;
}
.ant-dropdown {
  z-index: 2070;
}
.ant-select-dropdown {
  z-index: 2070;
}
.ant-picker-dropdown {
  z-index: 2070;
}

@media (min-width: 576px) {
  .wrapper {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .wrapper {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .wrapper {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .wrapper {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .wrapper {
    max-width: 1320px;
  }
}
