@import "@app/styles/vars.scss";

.form {
  background-color: $white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  :global(.form-item) {
    margin-bottom: 15px;
    label {
      font-weight: 700;
      font-size: 12px;
      line-height: 140.62%;
      color: $black-75;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    gap: 10px;
    :global(.ant-typography) {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $cold-gray;
    }
    .submit {
      border-radius: 4px;
      padding: 12px 62px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }
  &.modal {
    padding: 0;
  }
}
