@import "../vars";

.form-item {
  display: flex;
  flex-direction: column;
  .label {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: $black-75;
    margin-bottom: 4px;
    text-align: left;
    &.disabled {
      color: $black-50;
    }
  }

  .error-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $error;
  }

  .ant-input-affix-wrapper {
    height: 35px;
    padding: 8px 7px;
    border: 1px solid $border;
    border-radius: 4px;

    &:focus {
      box-shadow: none;
      border-color: $blue;
    }

    .ant-input {
      height: unset;
    }
  }
  .ant-input-affix-wrapper {
    height: auto;
  }
  .ant-input-affix-wrapper-textarea-with-clear-btn {
    .ant-input {
      padding-right: 35px;
    }
    .ant-input-clear-icon {
      right: 22px;
      top: 12px;
    }
  }
  .ant-input {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $black-75;

    border: 1px solid $border;
    border-radius: 4px;
    padding: 8px 7px;
    height: 35px;

    &::placeholder {
      color: $black-25;
    }

    &:hover {
      border-color: $light-blue;
    }

    &:focus {
      box-shadow: none;
      border-color: $blue;
    }

    &:disabled {
      color: $black-25;
      background-color: $gray-warm;
      border: none;
    }
  }
}