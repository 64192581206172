@import "@app/styles/vars.scss";

.teamlead {
  text-align: left;
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
  @media (max-width: 1000px) {
    display: block;
    margin: 15px auto 40px;
  }
  .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: $black;
  }
  .cards {
    max-width: 265px;
    width: 100%;
    padding: 0 32px;
    display: block;
    justify-content: center;
    gap: 16px;
    border-right: 1px solid $platina;
    @media (max-width: 1000px) {
      max-width: 100%;
      display: flex;
      margin: 0 auto;
      border: none;
      padding: 0;
    }
    @media (max-width: 700px) {
      flex-direction: column;
      align-items: center;
      gap: 0;
    }
  }
  .list {
    margin-top: 16px;
    margin-left: 32px;
    width: 100%;
    @media (max-width: 1000px) {
      margin-left: 10px;
    }
    .list_title {
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: $text;
      margin-top: 25px;
      display: block;
    }
    .filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .sort {
        padding: 10px;
        background: $white;
        border: none;
        border-radius: 4px;
        width: 44px;
        height: 44px;
      }
      .search {
        max-width: 525px;
        width: 40%;
        //background-color: $white;
        //padding: 10px;
        //border-radius: 4px;
        :global(.ant-select-selector) {
          padding-left: 10px;
          padding-top: 8px;
          padding-bottom: 8px;
        }
        :global(.ant-input-affix-wrapper) {
          //border: 1px solid $silver;
          //border-radius: 4px;
          //padding: 10px 13px 10px 16px;
          padding: 10px;
          :global(.ant-input-prefix) {
            color: $black-25;
            font-size: 18px;
            margin-right: 13px;
          }
          :global(.ant-input) {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.24px;
          }
          :global(.ant-input::placeholder) {
            color: $platina;
          }
        }
        &:global(.ant-select-selection-overflow) {
          margin-right: 6px;
        }
      }
    }
    .pagination {
      margin-top: 8px;
      background-color: $white;
      padding: 15px 0;
      text-align: center;
      &:global(.ant-pagination) {
        display: flex;
        justify-content: center;
        :global(.ant-pagination-total-text) {
          margin-left: 40px;
          order: 2;
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: $black-50;
          display: flex;
          align-items: center;
        }
        :global(.ant-pagination-options) {
          order: 3;
          margin-right: 16px;
        }
        :global(.ant-pagination-item) {
          border: none;
        }
        :global(.ant-pagination-item-active) {
          background-color: $blue-light;
          a {
            color: $black-75;
          }
        }
        :global(.ant-pagination-prev), :global(.ant-pagination-next) {
          button {
            border: none;
          }
        }
      }
    }
  }
}


