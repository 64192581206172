@import "@app/styles/vars.scss";

.list {
  width: 100%;
  .title {
    margin-bottom: 16px;
    :global(.ant-typography) {
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: $cold-dark-gray;
    }
  }
  .elements {
    .list_empty {
      background-color: $empty-gray;
      border-radius: 4px;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      padding: 5px 0;
      span {
        color: $black-50;
      }
    }
  }
}

.category {
  overflow: hidden;
  background-color: $white;
  border-radius: 4px;
  box-shadow: (0 1px 5px rgba(0, 0, 0, 0.05)), (0px 2px 10px rgba(19, 115, 236, 0.25));
  &:global(.sortable-chosen) {
    box-shadow: 0 21px 20px rgba(29, 125, 232, 0.13);
    border-radius: 4px;
    background-color: $blue-light;
  }
  .panel {
    &__content {
      display: flex;
      align-items: center;
    }
    :global(.ant-collapse-header) {
      display: flex;
      align-items: center;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05), 0 2px 10px rgba(19, 115, 236, 0.25);
      padding: 10px;
      :global(svg.handle) {
        width: 24px;
        height: 24px;
        &:hover {
          cursor: grabbing;
        }
      }
    }
    :global(.ant-collapse-expand-icon) {
        //transform: rotate(90deg);
        padding-inline-end: 12px !important;
      svg {
        color: $cold-gray;
      }
    }
    :global(.ant-collapse-header[aria-expanded="true"]) {
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05), 0 2px 10px rgba(19, 115, 236, 0.25);
      border-radius: 4px 4px 0 0;
      transition: 0.3s ease-in-out all;
      :global(.ant-collapse-expand-icon) {
        transform: rotate(180deg);
        top: 38%;
        padding-inline-end: 12px;
      }
    }
  }
  .action {
    margin-left: auto;
    margin-right: 20px;
  }
  &:global(.isDragging) {
    height: 52px;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  :global(.ant-dropdown-trigger) {
    display: flex;
    margin-right: 5px;
  }
}

.header {
  &__label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $cold-gray;
  }

  &__value {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $text;
  }
}
.dragIcon {
  color: $platina;
  margin-right: 5px;
}

.skills {
  &__header {
    display: flex;
    align-items: center;
    padding-left: 39px;
    padding-right: 13px;
    margin-bottom: 2px;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $cold-gray;
    .name {
      flex: 3;
    }
    .level {
      flex: 1;
    }
  }
}