@import "../../../app/styles/vars";

.main-template {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
}

.main-template__header-wrapper {
  position: relative;
  min-height: 72px;
}

.main-template__sidebar-content-wrapper {
  display: flex;
  height: calc(100vh - 72px);
}

.main-template__sidebar-wrapper {
  position: relative;
  height: 100%;
  z-index: 20;
}

.main-template__content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  @media(max-width: 1024px) {
    padding-left: 72px;
  }
}

.main-template__secondary-header {
  height: 86px;
}

.main-template__secondary-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .secondary-header-left {
    display: flex;
  }
  .secondary-header-right {
    padding-right: 70px;
    &-title {
      color: $cold-gray;
    }
  }
}

.main-template__outlet-wrapper {
  width: 100%;
  flex-grow: 1;
  background-color: #F4F7FB;
  padding-left: 70px;
  padding-right: 70px;
  @media (max-width: 1065px) {
    padding-left: 20px;
    padding-right: 30px;
  }
}

.main-template__footer-wrapper {
  justify-self: flex-end;
}
