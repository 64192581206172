@import "@app/styles/vars.scss";
.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  .text {
    font-size: 16px;
  }
  .input {
  }
  .timer {
    :global(.ant-statistic-content) {
      color: $dark-blue;
      font-size: 14px;
      margin-right: 8px;
      transition: 0.3s ease all;
    }
    &:hover {
      :global(.ant-statistic-content) {
        color: $white;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding-bottom: 50px;
  }
}