@import "@app/styles/vars.scss";

.item {
  display: flex;
  align-items: center;
  padding-left: 5px;
  height: 36px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background-color: $white;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  .name {
    flex: 3;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $cold-dark-gray
  }

  .level {
    flex: 1;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $text;
  }
  .dragIcon {
    color: $platina;
    margin-right: 5px;
    cursor: pointer;
    &:hover {
      cursor: grabbing;
    }
  }
}