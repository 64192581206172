@import "../../app/styles/vars";

.footer {
  padding-left: 24px;
  padding-right: 24px;
}

.footer__top-container {
  display: flex;
  justify-content: space-between;
  padding: 24px 50px 21px 24px;
  border-bottom: 1px solid #D2D6DA;
}

.footer__category-heading {
  font-weight: 700;
  font-size: 16px;
  line-height: 140.62%;
  color: $cold-dark-gray;
  margin-bottom: 8px;
}

.footer__category-external-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: $dark-blue;

  &:hover {
    color: $blue;
    text-decoration-line: underline;
  }

  &:active {
    color: $dark-blue;
    text-decoration-line: underline;
  }
}

.footer__category-internal-link {
  color: $cold-gray;

  &:hover {
    color: $blue;
  }

  &:active {
    color: $dark-blue;
  }
}

.footer__category-mail {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $cold-gray;
  cursor: pointer;
}

.footer__bottom-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px 35px 25px;
}

.footer__copyright {
  display: flex;
  align-items: center;
}

.footer__copyright-logo {
  margin-right: 9px;
  color: $platina;
}

.footer__copyright-text {
  color: $cold-gray;
}

.footer__social-media-list {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.footer__social-media-item {
  list-style-type: none;

  &:not(:last-child) {
    margin-right: 44px;
  }
}

.footer__social-media-link {
  display: flex;
  color: $platina;

  &:hover {
    color: $blue;
  }
}
