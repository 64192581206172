@import "src/app/styles/vars";

.header {
  position: fixed;
  display: flex;
  height: 72px;
  width: 100%;
  background-color: $dark-blue;
  padding: 12px 25px;
  z-index: 10;
  overflow: hidden;
  @media (max-width: 1024px) {
    padding: 12px 25px 12px 72px;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  &__right {
    display: flex;
    align-items: center;
  }
}
.select {
  margin-right: 30px;
  @media (max-width: 1024px) {
    margin-right: 20px;
  }
  @media (max-width: 500px) {
    margin-right: 5px;
  }
}
.logo {
  color: $white;
  cursor: pointer;
}


.header__hamburger {
  display: none;
  position: absolute;
  top: 27px;
  left: 27px;
  @media (max-width: 1024px) {
    display: block;
  }
}
