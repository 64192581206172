@import "src/app/styles/vars";

.form-item {

  .ant-select {

    font-weight: 400;
    font-size: 14px;
    color: $black-75;

    &:hover {
      .ant-select-selector {
        border-color: $light-blue;
      }
    }

    .ant-select-selection-search {
      top: 2px;
      right: unset;
      bottom: unset;
      left: unset;
    }

    .ant-select-selector {
      border: 1px solid $border;
      border-radius: 4px;
      height: 35px;
      padding-left: 7px;
      padding-right: 7px;

      .ant-select-selection-placeholder{
        height: 100%;
        padding-top: 7px;
        padding-bottom: 8px;
        line-height: 20px;
      }
      .ant-select-selection-item {
        height: 100%;
        padding-top: 7px;
        padding-bottom: 8px;
        line-height: 20px;
        color: $black-75;

      }
    }
    .ant-select-selection-overflow {
      height: 30px;
      overflow: hidden;
      display: flex;
      .ant-select-selection-overflow-item {
        height: 30px;
        font-size: 12px;
        line-height: 25px;
        .ant-select-selection-item {
          box-sizing: border-box;
          height: 25px;
          padding-top: 2px;
          padding-bottom: 2px;
        }
      }
    }
  }
}

