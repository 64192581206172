@import "@app/styles/vars.scss";

.list {
  width: 100%;
  .title {
    margin-bottom: 16px;
    :global(.ant-typography) {
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: $cold-dark-gray;
    }
  }
  .elements {
    .list_empty {
      background-color: $empty-gray;
      border-radius: 4px;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      padding: 5px 0;
      span {
        color: $black-50;
      }
    }
    .element {
      display: flex;
      word-break: break-word;
      align-items: center;
      background-color: $white;
      color: $cold-dark-gray;
      padding: 8px 10px;
      margin-bottom: 8px;
      box-shadow: (0 1px 5px rgba(0, 0, 0, 0.05)), (0px 2px 10px rgba(19, 115, 236, 0.25));
      border-bottom: 1px solid #d9d9d9;
      border-radius: 4px;
      position: relative;
      :global(svg.handle) {
        &:hover {
          cursor: grabbing;
        }
      }
      &:global(.sortable-chosen) {
        box-shadow: 0 21px 20px rgba(29, 125, 232, 0.13);
        border-radius: 4px;
        background-color: $blue-light;
      }
      svg {
        margin-right: 6px;
      }
      .certificate {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
      .more {
        position: absolute;
        right: 10px;
        top: 8px;
      }
    }
  }
  .dragIcon {
    color: $platina;
    margin-right: 5px;
  }
}