@import "@app/styles/vars.scss";

.root {
  .content {
    display: flex;
    justify-content: space-between;
    gap: 50px;
    @media (max-width: 1065px) {
      flex-direction: column;
      gap: 25px;
    }
    .add_section {
      width: 100%;
      .add_title {
        margin-bottom: 10px;
        :global(.ant-typography) {
          font-weight: 400;
          font-size: 16px;
          line-height: 23px;
          color: $text;
        }
        svg {
          color: $cold-gray;
        }
      }
    }
  }
}
