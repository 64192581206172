@import "vars";

.ant-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  padding: 9px 25px;
  border-radius: 4px;
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;

  &.ant-btn-primary {
    background-color: $blue;
    color: $white;
  }
  &.ant-btn-default {
    padding: 12px 26px;
    background: $border-light;
    color: $dark-blue;
    &:hover {
      color: $white;
    }
  }

  &:disabled {
    background: $disabled;
    border-radius: 4px;
    color: $white;
  }

  &:hover {
    background-color: $warm-blue;
    box-shadow: 0 4px 8px rgba(19, 115, 236, 0.25);
  }

  &:active {
    background-color: $dark-blue;
  }

  &:focus {
    box-shadow: 0 0 0 2px $light-blue;
  }

  >svg {
    //margin-right: 8px;
  }
}
.ant-dropdown-menu-title-content {
  line-height: 16px;
}