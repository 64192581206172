@import "@app/styles/vars.scss";

.card {
  width: 32px;
  height: 45px;
  display: block;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  margin: 0 auto;

  &__image {
    display: block;
    overflow: hidden;
    opacity: 1;
    height: 45px;
    border-radius: 4px;
    z-index: 1;
    transition: 0.3s ease all;
  }
  &__button {
    padding: 12px 10px !important;
    width: 100%;
    border: none;
  }
  :global(.ant-btn:hover) {
    box-shadow: 0 4px 8px rgba(230, 234, 241, 0.33);
  }
}








