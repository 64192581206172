@import "@app/styles/vars.scss";

.form {
  display: flex;
  flex-direction: column;
  :global(.form-item:first-child ) {
    margin-bottom: 20px;
  }
  :global(.form-item .ant-input-affix-wrapper) {
    height: 44px;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 19px auto 26px;
    text-align: end;
    color: $blue;
    transition: 0.3s ease all;
    &__text {
      cursor: pointer;
      &:hover {
        color: $dark-blue;
      }
    }
  }
  .copy {
    position: relative;
    max-width: 135px;
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    width: 100%;
    :global(.ant-btn) {
      width: 100%;
    }
  }
}

