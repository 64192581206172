@import "src/app/styles/vars.scss";

.modal {
  padding: 6px 20px 20px 20px;
  .info {
    display: flex;
    justify-content: space-between;
    gap: 22px;
    margin-bottom: 30px;
    white-space: pre-line;
    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 140.62%;
      display: flex;
      color: $cold-dark-gray;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    button {
      width: 100%;
    }
  }
}
