@import "src/app/styles/vars";
.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
}
.title {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    position: relative;
    padding: 10px;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: $black-75;
    margin-bottom: 0;
  }
  &__line {
    width: 44px;
    height: 2px;
    background-color: $black-75;
  }
}