@import "@app/styles/vars.scss";
.instructions-wrapper {
  margin-top: 40px;
  border-top: 1px solid $border;
  padding-top: 16px;
  padding-bottom: 60px;
}

.instructions-wrapper__heading {
  font-size: 16px;
  line-height: 23px;
  color: $cold-dark-gray;
  margin-bottom: 16px;
}
