@import "../../../app/styles/vars";

.group-label {
  display: flex;
  align-items: flex-start;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;

  &:hover {
    .group-label__icon {
      color: $blue;
    }
  }

  &:active {
    .group-label__icon {
      color: $dark-blue;
    }
  }
}

.group-label__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: $text;
  margin-right: 16px;
}

.group-label__icon {
  width: 16px;
  height: 16px;
  color: $cold-gray;

  svg {
    width: 100%;
    height: 100%;
  }
}
