@import "@app/styles/vars.scss";

.tabs {
  :global(.ant-tabs) {
    :global(.ant-tabs-nav) {
      //position: sticky;
      //top: 108px; // height of the header
      z-index: auto;
      margin-bottom: 0;
      padding-bottom: 16px;
      background-color: $your-highness;
      border-bottom: none;

      @media(max-width: 1065px) {
        top: 167px; // height of the header
      }
      &:before {
        content: none;
      }
    }

    :global(.ant-tabs-nav-operations) {
      display: none;
    }

    :global(.ant-tabs-nav) {
      border: 1px solid $blue;
      border-radius: 4px;
      overflow: hidden;
      padding: 0;
      margin-bottom: 16px;

      @media (max-width: 470px) {
        z-index: 1000;
      }
    }

    :global(.ant-tabs-nav-list) {
      width: 100%;
      height: 31px;
      @media (max-width: 670px) {
        width: unset;
      }
    }

    :global(.ant-tabs-tab) {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-left: 0 !important;
      border-radius: 0 !important;
      height: 100%;
      padding: 0 14px;
      border-top: none;
      border-bottom: none;
      border-right-width: 0px;
      border-left-width: 0px;
      background: rgb(250, 250, 250);

      :global(.ant-tabs-tab-btn) {
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        color: $dark-blue;
      }

      &:not(:first-child) {
        border-left: 1px solid $blue;
      }


    }
    :global(.ant-tabs-tab-active) {
      background-color: $blue;

      :global(.ant-tabs-tab-btn) {
        color: $white;
      }
    }

    :global(.ant-tabs-nav-more) {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      border-radius: 4px;
      background-color: $your-highness;
    }
  }
}
