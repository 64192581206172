@import "input";
@import "select";
.ant-collapse.filling-instructions {
  .ant-collapse-item {
    margin-bottom: 16px;

    .ant-collapse-header {
      height: 35px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $cold-dark-gray;
      padding: 8px 7px 8px 12px;
      border-radius: 4px 4px 0 0;

      svg {
        color: $cold-dark-gray;
      }
    }
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      background-color: $blue-light;
      border: 1px solid $blue;
    }
  }

  .ant-collapse-content-active {
    background-color: $white;
  }
}

.ant-breadcrumb {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $black-75;
}