@import "@app/styles/vars.scss";

.form {
  background-color: $white;
  padding: 24px;
  :global(.form-item) {
    margin-bottom: 32px;
    label {
      font-weight: 700;
      font-size: 12px;
      line-height: 140.62%;
      color: $black-75;
    }
  }
  .date {
    display: flex;
    align-items: flex-start;
    :global(.form-item:nth-child(1)),
    :global(.form-item:nth-child(2)) {
      flex: 2;
      margin-right: 15px;
    }
    :global(.form-item:nth-child(3)) {
      flex: 1;
      align-items: center;
    }
    :global(.datepicker-field) {
      width: 100%;
    }
    :global(.datepicker-field + .datepicker-field) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    gap: 10px;
    :global(.ant-typography) {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $cold-gray;
    }
    .submit {
      border-radius: 4px;
      padding: 12px 62px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.09em;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }
  &.modal {
    padding: 0;
  }
}
