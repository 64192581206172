@import "@app/styles/vars.scss";

.authentication {
  background-color: $blue;
  position: relative;
}
.inner {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  background-attachment: fixed;
  height: 100vh;
}
.lang {
  text-align: end;
}
.title {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    position: relative;
    padding: 58px 10px 0;
    font-weight: 700;
    font-size: 49.0162px;
    line-height: 140.62%;
    color: $white;
  }
  &__line {
    width: 44px;
    height: 2px;
    background-color: $white;
    margin-top: 40px;
  }
}
.form {
  background-color: $white;
  margin-left: auto;
  margin-right: auto;
  filter: drop-shadow(0px 8px 32px rgba(19, 115, 236, 0.16));
  max-width: 420px;
  width: 100%;
  position: relative;
  z-index: 1;
  img {
    margin-top: 64px;
    margin-bottom: 48px;
  }
  &__inner {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    .ant-modal {
      margin-top: 30px;
      &-body {
        line-height: 20px;
      }
    }
  }
}
.footer {
  text-align: center;
  margin-top: 141px;
  span {
    color: $black-50;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
.logo {
  margin-top: 64px;
  margin-bottom: 55px;
}
