@import "src/app/styles/vars";
.container {
  background-color: $your-highness;
  min-height: 100vh;
}
.header {
  background-color: $dark-blue;
  padding: 12px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo {
    color: $white;
    cursor: pointer;
  }
  .logout {
    font-size: 18px;
    color: $white;
    cursor: pointer;
    transition: 0.3s ease all;
    &:hover {
      color: $cold-dark-gray3;
    }
  }
}

.form {
  max-width: 1228px;
  margin: 0 auto;
  padding: 15px;
}