$base-color: #fff;

$black: #000000;
$black-75: #565E65;
$black-50: #8D9097;
$black-25: #C6C7CB;
$black-10: #E6E7E8;

$brown: #505050;

$text: #1E2832;
$cold-dark-gray: #3B4256;
$cold-dark-gray2: #6D7280;
$cold-dark-gray3: #61677B;
$cold-gray: #848E99;
$empty-gray: #E7EFF9;
$icon-gray: #A7A9B0;
$disabled-gray: #7D8EA5;
$platina: #C7CCD1;
$silver: #DDE1E6;

$border-2: #B5C5DA;
$border: #D5DEE9;
$border-light: #E6EAF1;
$blue-light: #D2E5FA;
$blue-light-2: #F1F7FF;
$light-hover: #F8FBFF;
$light-light-blue: #F9FBFD;
$blue-lighter: #E1EFFF;
$your-highness: #F4F7FB;
$hover-color: #F7F8FB;
$bg: #FAFAFA;
$gray-warm: #F4F4F5;
$white: #FFFFFF;
$border-light-2: #F0F2F6;

$super-dark-blue: #033087;
$dark-blue: #0044C6;
$blue: #1D7DE8;
$light-blue: #5BBAFB;
$warm-light-blue: #62B0FF;
$warm-blue: #3991F3;
$super-light-blue: #8EB9EA;
$disabled: #AED0F7;

$success: #0DAD40;
$success-2: #219653;
$success-background: #C8E6C9;

$turquoise: #28A7C2;

$orange: #FF6A3B;
$warning: #FC9426;
$warning-background: #FFECB3;

$error: #E53935;
$error-background: #FFCDD2;

$red-disable: #EE9593;
$red-light-2: #FF918E;
$red-light: #FF5854;
$red-dark: #DF2F2B;

$xxl: 1400px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;

$height: clamp(700px, 100vh, 100vh);
