@import "@app/styles/vars.scss";

.item {
  align-items: center;
  border-bottom: 1px solid $border;
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0 4px 0;
    @media (max-width: 620px) {
      flex-direction: column;
      align-items: center;
    }
    &__id {
      color: #727272;
      position: absolute;
      opacity: 0.1;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 60px;
      font-weight: 400;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    &__item {
      display: flex;
      margin-left: 10px;
      @media (max-width: 620px) {
        flex-direction: column;
        margin-left: 0;
        align-items: center;
      }
      .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        width: 48px;
        border-radius: 50%;
        color: $white;
        background-color: $turquoise;
        font-weight: 400;
        font-size: 16px;
        line-height: 13px;
        text-transform: uppercase;
      }
      .info {
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        line-height: 24px;
        font-weight: 400;
        letter-spacing: -0.24px;
        &__name {
          font-size: 16px;
          color: $brown;
        }
        &__file {
          font-size: 12px;
          color: $cold-gray;
        }
        @media (max-width: 620px) {
          align-items: center;
        }
      }
      .updated {
        display: flex;
        align-items: flex-end;
        font-size: 11px;
        color: $cold-gray;
      }
      .lang {
        display: flex;
        gap: 16px;
        text-align: center;
        @media (max-width: 620px) {
          margin: 20px 0;
        }
      }
      .document {
        max-width: 160px;
        font-weight: 400;
        font-size: 12px;
        line-height: 140.62%;
        display: flex;
        align-items: center;
        color: $cold-dark-gray;
        margin-left: 24px;
        @media (max-width: 620px) {
          margin-left: 0;
          font-size: 14px;
        }
      }
    }
  }
}


