@import "@app/styles/vars.scss";

.error {
  background-color: #0A1133;
  background-image: url('../../app/assets/images/error-page.png');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: bottom;
  height: 100vh;
  color: $white;
  padding: 20px 74px;
  :global(.ant-result-title) {
    color: white;
    margin-top: 75px;
    font-weight: 700;
    font-size: 24px;
  }
  .select {
    text-align: end;
  }
  .content {
    color: white;
    max-width: 468px;
    width: 100%;
    margin: 25px auto 32px;
    font-weight: 400;
    font-size: 16px;
    .hint {
      color: $black-50;
    }
  }
  .home {
    background-color: inherit;
    border: 2px solid #1D7DE8;
    color: $blue;
    padding: 12px 40px;
  }
}


