@import "@app/styles/vars.scss";

.cv_card {
  margin-top: 20px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
  overflow: auto;
  max-width: 280px;
  width: 100%;
  .cv_card_custom {
    position: absolute;
    right: 0;
    :global(.ant-btn) {
      background: #fbfcfd;
    }
    :global(.ant-btn:hover){
      background: #fbfcfd;
    }
  }
  .cv_card_inner {
    padding: 20px 32px 0;
    &:global(.large) {
      padding: 0 56px;
    }
    .cv_card_date {
      display: flex;
      margin-bottom: 5px;
      font-weight: 400;
      font-size: 10px;
      line-height: 140.62%;
      color: $cold-gray;
      justify-content: center;
      text-align: center;
    }
    .cv_card_title {
      max-width: 310px;
      margin-top: 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 140.62%;
      color: $cold-dark-gray;
      &:global(.hidden) {
        visibility: hidden;
      }
    }
    .cv_card_info {
      display: grid;
      max-width: 127px;
      width: 100%;
      height: 153px;
      margin: 0 auto 16px;
      text-align: center;
      justify-content: center;
      border: 0.9px solid $disabled;
      border-radius: 2.7px;
      font-weight: 500;
      line-height: 141.24%;
      text-transform: capitalize;
      span {
        font-size: 10px;
        color: $super-light-blue;
      }
      .cv_card_lang {
        font-size: 12px;
        color: $cold-dark-gray;
      }
    }
    .cv_card_buttons {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      .cv_card_edit, .cv_card_download {
        background: $white;
        border: 1px solid $blue;
        border-radius: 50%;
        color: $blue;
        width: 40px;
        height: 40px;
        padding: 0;
      }
      .cv_card_download {
        margin-right: 15px;
      }
      //todo зачем тут это
      .cv_card_edit + .cv_card_edit {
        margin-left: 24px;
      }
      .cv_card_create {
        //width: 168px;
        //background: $blue;
        //border-radius: 4px;
        //font-weight: 500;
        //font-size: 14px;
        //line-height: 20px;
        //align-items: center;
        //text-align: center;
        //letter-spacing: 0.09em;
        //text-transform: uppercase;
        //color: $white;
        //padding: 12px 18px;
        padding: 12px 10px;
      }
      .cv_card_create:disabled {
        background: $disabled;
        padding: 12px 10px;
      }
      //.cv_card_custom {
      //  margin-right: -15px;
      //  padding: 0px 11px;
      //  :global(.ant-btn) {
      //    background: #fbfcfd;
      //  }
      //  :global(.ant-btn:hover){
      //    background: #fbfcfd;
      //  }
      //}
    }
  }
  .cv_card_footer {
    margin-bottom: 18px;
    text-align: center;
    .cv_card_hint {
      font-weight: 400;
      font-size: 12px;
      line-height: 140.62%;
      color: $cold-gray;
    }
  }
}




