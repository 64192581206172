@import "@app/styles/vars.scss";

.card {
  width: 64px;
  height: 45px;
  display: block;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  margin: 0 auto;
  &__image {
    display: block;
    overflow: hidden;
    opacity: 1;
    height: 45px;
    border-radius: 4px;
    z-index: 1;
    transition: 0.3s ease all;
  }
  &__actions {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 64px;
    height: 45px;
    background-color: $blue;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 10px;
    overflow: hidden;
    opacity: 0;
    :global(.ant-divider) {
      background-color: $light-blue;
      margin: 5px 0;
      height: 20px;
    }
  }
  &__content {
    padding: 0 4px;
    color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    :global {
      height: 32px;
    }
    .icon {
      transition: 0.3s ease-in all;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  &:hover:not(svg) {
    .card__image {
      opacity: 0;
    }
    .card__actions {
      opacity: 1;
    }
  }
  &:global(.disabled) {
    opacity: 0.7;
    cursor: not-allowed;
    &:hover {
      .card__image {
        display: inherit;
        opacity: 1;
      }
      .card__actions {
        display: none;
      }
    }
  }
}

:global(.card__date) {
  display: flex;
  justify-content: center;
  font-size: 11px;
  color: $cold-gray;
}








