@import "@app/styles/vars.scss";

.employee {
  text-align: center;
  .inner {
    padding: 40px 0;
    .header {
      &:global(.hidden) {
        display: none;
      }
      .subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $cold-dark-gray;
      }
    }
    .cards {
      display: flex;
      justify-content: center;
      gap: 16px;
    }
  }

}


